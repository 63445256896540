import { InboxLabelEnum, LabelActionMapping } from "@/stores/enums/label.enum";
import { useInboxStore } from "@/stores/inbox";
import { useThreadStore } from "@/stores/thread";
import ReleaseToast from "@/components/partials/ReleaseToast.vue";
import type {
  InboxLabels,
  InboxSubType,
  InboxType,
} from "@/stores/types/inbox";
import {
  Database,
  DataSnapshot,
  onChildAdded,
  onChildRemoved,
  onValue,
  ref,
} from "@firebase/database";
import router from "@/router";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRootStore } from "@/stores/root";
import { defineAsyncComponent } from "vue";
import {
  base64Decoder,
  inboxTypetoInboxTypeV3,
  moveItemInArr,
  removeItemsWithCaution,
} from "@/utils/helperFunction";
import type {
  AssignedUser,
  ThreadAttachment,
  ThreadItemMsg,
  ThreadTag,
} from "@/stores/types/thread";
import useSound from "@/functions/useSound";
import { useEmailStore } from "@/stores/email";
import dayjs from "dayjs";
import { ThreadPriorityEnum } from "@/stores/enums/priority.enum";
import { nanoid } from "nanoid";
import { useToast, POSITION } from "vue-toastification";
import type { TeamMate } from "@/stores/types/user";
import useMitt from "@/functions/useMitt";

const toast = useToast();
const { emitter } = useMitt();

type MessageData = {
  attachments?:
    | (
        | {
            id: number;
            mime_type: string;
            name: string;
            size: string;
            url: string;
          }
        | {
            attachment_type: string;
            attachment_url: string;
            file_name: string;
            id: number;
          }
        | ThreadAttachment
      )[]
    | {
        content_type: string;
        media_url: string;
      };
  body: string;
  id: number;
  time: string;
  timeStamp?: number;
  type: InboxType;
  subtype?: InboxSubType;
  sentBy?: {
    avatarTag: string;
    avatarUrl: string;
    email: string;
    firstname: string;
    id: number;
    lastname: string;
  };
};

class SocketListener {
  private _db: Database;
  private _parentRef: string;
  private _lastExecutedStatsTime: number | null = null;

  constructor(db: Database, parentRef: string) {
    this._db = db;
    this._parentRef = parentRef;
  }

  private _generateRef(refPath: string, parentRef?: string) {
    if (!parentRef) {
      parentRef = this._parentRef;
    }
    return ref(this._db, parentRef + refPath);
  }

  private _getThreadPushPermission = (data: {
    assignedTo?: AssignedUser;
    isArchived?: boolean;
    isSnoozed?: boolean;
    isSpam?: boolean;
    isDeleted?: boolean;
    category?: number;
  }): boolean => {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const label = router.currentRoute.value.params.label as InboxLabels;

    const isInCategory = (category: number | undefined) =>
      data.category === category || data.category === undefined;

    const isAssignedToUser = (assignedTo: AssignedUser | undefined) =>
      assignedTo?.id === user.value?.id;

    const isLabelMatching = (label: InboxLabels) => {
      switch (label) {
        case "all":
        case "awaitingReply":
        case "inbox":
          return isInCategory(0);
        case "unassigned":
          return !data.assignedTo && isInCategory(0);
        case "assigned":
          return !!data.assignedTo && isInCategory(0);
        case "mine":
          return isAssignedToUser(data.assignedTo) && isInCategory(0);
        case "closed":
          return data.isArchived ?? false;
        case "snoozed":
          return data.isSnoozed ?? false;
        case "spam":
          return data.isSpam ?? false;
        case "trash":
          return data.isDeleted ?? false;
        case "social":
          return data.category === 1;
        case "updates":
          return data.category === 2;
        case "promotions":
          return data.category === 3;
        case "forums":
          return data.category === 4;
        default:
          return false;
      }
    };

    return isLabelMatching(label);
  };

  private _populateThreadItem(
    type: "incoming" | "outgoing",
    msgData: MessageData,
    threadId: string,
    by?: {
      id: number;
      first_name?: string;
      fullname?: string;
      img_hash?: string;
      last_name?: string;
      avatarTag?: string;
    }
  ) {
    const userStore = useUserStore();
    const threadStore = useThreadStore();

    const { threadsDetailMap, newThreadCountMap } = storeToRefs(threadStore);
    const { user } = storeToRefs(userStore);

    // don't push if sender and receiver both are same user
    if (user.value?.id === by?.id) return;

    // populate data
    if (
      msgData.type === "chat" ||
      msgData.type === "sms" ||
      msgData.type === "whatsapp" ||
      msgData.type === "facebook" ||
      msgData.type === "instagram" ||
      msgData.type === "instagram-dm" ||
      msgData.type == "twitter" ||
      msgData.type == "twitterdm"
    ) {
      let attachments: ThreadItemMsg["attachments"] = [];

      if (msgData.attachments && Array.isArray(msgData.attachments)) {
        attachments = msgData.attachments.map((a) => {
          if ("attachment_type" in a) {
            return {
              contentType: a.attachment_type,
              extension: "",
              url: a.attachment_url,
              filename: a.file_name,
              filesize: 0,
              id: a.id,
            };
          } else if ("mime_type" in a) {
            return {
              contentType: a.mime_type,
              extension: "",
              url: a.url,
              filename: a.name,
              filesize: Number(a.size),
              id: a.id,
            };
          } else {
            return a;
          }
        });
      } else if (msgData.attachments && !Array.isArray(msgData.attachments)) {
        attachments = [
          {
            contentType: msgData.attachments.content_type as any,
            url: msgData.attachments.media_url,
            id: nanoid(),
            extension: "",
            filename: "",
            filesize: 0,
          },
        ];
      }

      newThreadCountMap.value[threadId]
        ? (newThreadCountMap.value[threadId] += 1)
        : (newThreadCountMap.value[threadId] = 1);
      const msg: ThreadItemMsg = {
        attachments,
        content: msgData.body,
        createdAt: msgData.time,
        type: "msg",
        deliveryStatus: "delivered",
        id: msgData.id?.toString(),
        msgType: msgData.type,
        order: type === "incoming" ? "received" : "sent",
      };
      if (msg.order == "received") {
        const contact =
          threadsDetailMap.value[threadId]?.threadDetail.contacts[0];
        msg.user = {
          id: contact?.id.toString() ?? nanoid(),
          firstName: contact?.firstname,
          lastName: contact?.lastname,
          name: contact?.firstname + " " + contact?.lastname,
          avatarTag: contact?.avatarTag,
        };
      } else if (by) {
        msg.user = {
          id: by.id?.toString() ?? nanoid(),
          firstName: by.first_name,
          lastName: by.last_name,
          name: by.fullname,
          avatarTag: by.avatarTag,
        };
      }
      threadsDetailMap.value[threadId]?.threadItems.push(msg);
    }
  }

  private _openAlertModal(msg: string) {
    const rootStore = useRootStore();
    const component = defineAsyncComponent(
      () => import("@/components/partials/AlertModal.vue")
    );

    rootStore.setModal<InstanceType<typeof component>>({
      component,
      props: {
        open: true,
        msg,
      },
    });
  }

  private _handleThreadAction(snapshot: DataSnapshot) {
    const userStore = useUserStore();
    const inboxStore = useInboxStore();
    const threadStore = useThreadStore();

    const { threads, threadsDetailMap } = storeToRefs(threadStore);
    const { isInboxUniversal } = storeToRefs(inboxStore);
    const { user } = storeToRefs(userStore);

    const data: {
      action: "close" | "delete" | "snooze" | "moveToInbox" | "spam" | "assign";
      inboxThreadMap: Record<string, unknown>;
      threadID: number[];
      managerID: number;
      user: {
        [key: string]: unknown;
        id: string;
        first_name: string;
        last_name: string;
      };
      assigned: any;
    } = snapshot.val();

    const threadIds = data.threadID;
    const actionBy = data.user.first_name + " " + data.user.last_name;
    const openThreadId = router.currentRoute.value.params.threadId as string;

    const params: Record<string, any> = router.currentRoute.value.params;
    const inboxId = params.id as string;
    const inboxLabel = params.label as string;
    let section = router.currentRoute.value.query.filter as undefined | string;
    if (section === "open" || !section) {
      section = inboxLabel;
    }
    const sectionLabel = LabelActionMapping[section];
    // Fetching stats

    // execute the function
    // fetch inbox async with stats to update the count
    if (isInboxUniversal.value) {
      inboxStore.fetchInboxes(true);
    } else {
      inboxStore.fetchInboxById(inboxId);
    }

    // donot trigger it for the same user
    if (Number(data.user.id) === user.value?.id) return;

    if (data.action === "assign") {
      if (data.assigned) {
        data.assigned = {
          avatarTag: data.assigned.avatarTag,
          avatarUrl: data.assigned.avatarUrl,
          email: data.assigned.email,
          firstname: data.assigned.firstname,
          id: data.assigned.id,
          lastname: data.assigned.lastname,
          displayName: data.assigned.firstname + " " + data.assigned.lastname,
        };
      } else {
        data.assigned = null;
      }
    }

    // Check if user is on the same thread or not
    if (threadIds.includes(parseInt(openThreadId))) {
      // if true, close thread broad view and remove the thread if label is not "closed"
      let path = `/inboxes/${inboxId}/${inboxLabel}/`;

      if (isInboxUniversal.value) {
        path = `/universal/me/${inboxLabel}?filter=open`;
      }

      if (data.action !== "assign") {
        router.replace(path);
      }

      let msgAction = "closed";

      switch (data.action) {
        case "delete":
          msgAction = "deleted";
          break;
        case "close":
          msgAction = "closed";
          break;
        case "snooze":
          msgAction = "snoozed";
          break;
        case "moveToInbox":
          msgAction = "moved";
          break;
        case "spam":
          msgAction = "marked as spam";
          break;
        case "assign":
          msgAction = "assigned";
          break;
      }
      if (data.action === "assign") {
        let modalMsg = `${actionBy} has unassigned this conversation.`;
        if (data.assigned) {
          let assignedTo =
            data.assigned.firstname + " " + data.assigned.lastname;
          if (assignedTo === actionBy) {
            assignedTo = "themselves";
          }
          modalMsg = `${actionBy} has ${msgAction} this conversation to ${assignedTo}.`;
        }
        const now = dayjs().toISOString();
        threadsDetailMap.value[openThreadId]?.threadItems?.push({
          type: "log",
          content: modalMsg,
          createdAt: now,
          id: modalMsg + now + "",
        });
        if (threadsDetailMap.value[openThreadId]?.threadDetail) {
          threadsDetailMap.value[openThreadId]!.threadDetail.assignedTo =
            data.assigned;
        }
      } else {
        // Open the alert modal
        this._openAlertModal(`${actionBy} has ${msgAction} this conversation.`);
      }
    }
    // filtering the threads
    if (data.action !== "assign") {
      if (data.action === sectionLabel) {
        emitter.emit("inbox-view-list-header:reload-threads", {
          loadThreadsPage: false,
        });
      } else {
        const { filteredList } = removeItemsWithCaution(
          threads.value,
          threadIds
        );
        threads.value = filteredList;
      }
    } else {
      let foundThreadIdx = -1;
      const foundThread = threads.value.find((t, idx) => {
        if (t.id === parseInt(openThreadId)) {
          foundThreadIdx = idx;
          return t;
        }
      });
      if (foundThread) {
        if (threadIds.includes(foundThread.id)) {
          threads.value[foundThreadIdx].assignedTo = data.assigned;
        }
      } else {
        if (openThreadId === "") {
          threads.value.forEach((t, idx) => {
            if (threadIds.includes(t.id)) {
              threads.value[idx].assignedTo = data.assigned;

              if (threadsDetailMap.value[t.id]?.threadDetail) {
                threadsDetailMap.value[t.id]!.threadDetail.assignedTo =
                  data.assigned;
              }
            }
          });
        }
      }
    }
  }

  private _handleThreadUpdates(
    snapshot: DataSnapshot,
    handlerFor: "incoming" | "outgoing"
  ) {
    const rootStore = useRootStore();
    const userStore = useUserStore();
    const threadStore = useThreadStore();
    const inboxStore = useInboxStore();
    const { playSound } = useSound("receive");

    const { pinnedUniversalInboxes } = storeToRefs(rootStore);
    const { user, userSettings } = storeToRefs(userStore);
    const { isInboxUniversal, inboxesMap, inboxThreadMovementSettings } =
      storeToRefs(inboxStore);
    const { threadsDetailMap, isThreadSharing, threads } =
      storeToRefs(threadStore);

    const data: {
      [key: string]: any;
      date: string;
      displayContact: string;
      threadID: number;
      inboxType: InboxType | "email";
      mailboxID: number;
      threadIDV2?: number;
      assignedTo: AssignedUser;
      category: number;
      contact: {
        first_name?: string;
        fullname?: string;
        id: number;
        img_hash?: string;
        last_name?: string;
      };
      tags: ThreadTag[];
      messageData: MessageData;
    } = snapshot.val();

    const bookmarkedInboxIds = new Set(
      pinnedUniversalInboxes.value.filter((i) => inboxesMap.value[i])
    );

    // Don't populate data if outgoing event getting triggered for the sending user.
    if (
      handlerFor === "outgoing" &&
      data.messageData.sentBy?.id === user.value?.id
    )
      return;

    if (data.isClosed && handlerFor === "outgoing") {
      return;
    }

    const {
      inboxType,
      threadIDV2,
      threadID,
      assignedTo,
      mailboxID,
      messageData,
      contact,
    } = data;

    const label = router.currentRoute.value.params.label as InboxLabels;
    const inboxId = router.currentRoute.value.params.id as "me" | string;
    const page = router.currentRoute.value.query.page as undefined | string;
    const search = router.currentRoute.value.query.search as undefined | string;
    const tag = router.currentRoute.value.query.tag as undefined | string;

    //dont append if there are search results
    if (search && handlerFor === "incoming") {
      return;
    }

    let validThreadId = threadID;
    if (inboxType === "chat" && threadIDV2) {
      validThreadId = threadIDV2;
    }

    // Push only if thread is not sharing and page is first page
    if (
      !isThreadSharing.value &&
      (!page || parseInt(page) === 1) &&
      !search &&
      !tag
    ) {
      // if thread already exist
      let foundThreadIdx = 0;
      const foundThread = threads.value.find((t, idx) => {
        if (t.id === validThreadId) {
          foundThreadIdx = idx;
          return t;
        }
      });

      if (foundThread) {
        foundThread.snippet = data.snippet;
        foundThread.snippetType = data.snippetType;
        foundThread.date = data.date;
        foundThread.isRead =
          handlerFor === "outgoing" ? foundThread.isRead : false; // For outgoing don't mark as unread

        if (foundThread.inboxType === "mail") {
          foundThread.msgCount += 1;
        }

        if (handlerFor === "outgoing") {
          if (
            // if all, assigned, unassigned and movement is inbound don't move
            ((label === "all" ||
              label === "assigned" ||
              label === "awaitingReply" ||
              label === "unassigned") &&
              inboxThreadMovementSettings.value[inboxId]
                ?.allUnassignedAssigned !== "inbound") ||
            // if mine and inbound don't move
            (label === "mine" &&
              inboxThreadMovementSettings.value[inboxId]?.mine !== "inbound") ||
            // for every other label except above move thread.
            ![
              "all",
              "assigned",
              "unassigned",
              "mine",
              "awaitingReply",
            ].includes(label)
          ) {
            moveItemInArr(threads.value, foundThreadIdx, 0);
          }
        } else {
          moveItemInArr(threads.value, foundThreadIdx, 0);
        }
      } else if (
        (!foundThread &&
          inboxId === "me" &&
          bookmarkedInboxIds.has(data.mailboxID.toString())) || // for universal
        (!foundThread && inboxId === data.mailboxID.toString()) // if user has opened the same inbox that he is getting listener data for
      ) {
        const allowPush = this._getThreadPushPermission(data);

        if (allowPush) {
          // remove the last thread item first only if threads limit has been reached.
          if (
            userSettings.value &&
            threads.value.length >= parseInt(userSettings.value.resultsPerPage)
          ) {
            threads.value.pop();
          }

          // unshift the new thread
          threads.value.unshift({
            draftOnly: false,
            assignedTo: assignedTo,
            attachments: [],
            date: data.date,
            hasDraft: false,
            id: validThreadId,
            inboxId: data.mailboxID.toString(),
            inboxType: inboxTypetoInboxTypeV3(
              data.inboxType === "email" ? "mail" : data.inboxType
            ),
            isArchived: data.isArchived ?? false,
            isDeleted: data.isDeleted ?? false,
            isRead: false,
            isSnoozed: data.isSnoozed ?? false,
            isSpam: data.isSpam ?? false,
            isStarred: data.isStarred ?? false,
            msgCount: 0,
            snippet: data.snippet,
            snippetType: data.snippetType === "2" ? "note" : "reply",
            snoozedAt: data.snoozedAt,
            subject: data.subject,
            priority: {
              source: "automated",
              value: ThreadPriorityEnum.choose,
            },
            tags: data.tags ?? [], // need to figure out,
            threadTitle: data.displayContact,
          });
        }
      }
    }

    // if threadID present in threadsDetailMap, refetch the threadDetail asynchronously
    if (threadsDetailMap.value[validThreadId]?.threadDetail) {
      if (inboxType === "mail" || inboxType === "email") {
        threadStore.fetchInboxThreadById(
          validThreadId,
          inboxId,
          InboxLabelEnum[label]
        );
      } else {
        this._populateThreadItem(
          handlerFor,
          messageData,
          validThreadId.toString(),
          (inboxType === "facebook" || inboxType === "chat") &&
            messageData.sentBy
            ? messageData.sentBy
            : contact
        );
      }
    }

    // fetch inbox async with stats to update the count
    const currentTime = new Date().getTime();

    if (
      !this._lastExecutedStatsTime ||
      currentTime - this._lastExecutedStatsTime >= 60 * 1000
    ) {
      // execute the function
      // fetch inbox async with stats to update the count
      if (isInboxUniversal.value) {
        inboxStore.fetchInboxes(true);
      } else {
        inboxStore.fetchInboxById(inboxId);
      }
      // update the last executed time
      this._lastExecutedStatsTime = currentTime;
    }

    if (handlerFor === "incoming") {
      if (
        (mailboxID === Number(inboxId) || inboxId === "me") &&
        userSettings.value?.notifSound === 1
      ) {
        // Play Sound
        if (
          inboxesMap.value[mailboxID].isPersonal &&
          inboxesMap.value[mailboxID].ownedBy
        ) {
          //Play sound for personal inbox owner
          if (Number(inboxesMap.value[mailboxID].ownedBy) === user.value?.id) {
            playSound();
          }
        } else {
          let dontPlaySound = false;
          if (inboxId === "me" && !bookmarkedInboxIds.has(mailboxID + "")) {
            dontPlaySound = true;
          }
          if (!assignedTo && !dontPlaySound) {
            // Play sound to all if null
            playSound();
            // Else play for the assigned user
          } else if (assignedTo?.id === user.value?.id && !dontPlaySound) {
            playSound();
          }
        }
      }
    }
  }

  private _handleShareDraftChanges(inboxId: string, threadId: string) {
    const threadStore = useThreadStore();

    const label = router.currentRoute.value.params.label as InboxLabels;

    // fetch threadDetail again
    threadStore.fetchInboxThreadById(
      parseInt(threadId),
      inboxId,
      InboxLabelEnum[label]
    );
  }

  /**
   * Triggers if new thread comes from any third party source.
   *
   * Returns a function, which will unsubscribe/detach the event on calling.
   */
  incomingThreadUpdatesListener() {
    const rootStore = useRootStore();
    const incomingRef = this._generateRef("/incoming");
    let i = 0;

    return onValue(incomingRef, (snapshot) => {
      // @href-https://stackoverflow.com/a/45063835/13024638
      // Stopping firebase to trigger on mount
      if (i !== 0) {
        this._handleThreadUpdates(snapshot, "incoming");
        rootStore.fetchNotification();
      }
      i++;
    });
  }

  /**
   * Triggers if user of helpwise sends a thread update.
   *
   * for updating the data for other helpwise user currently present on the same thread.
   * Returns a function, which will unsubscribe/detach the event on calling.
   */
  outgoingThreadUpdatesListener() {
    const rootStore = useRootStore();
    const outgoingRef = this._generateRef("/outgoing");

    let i = 0;

    return onValue(outgoingRef, (snapshot) => {
      if (i !== 0) {
        this._handleThreadUpdates(snapshot, "outgoing");
        rootStore.fetchNotification();
      }
      i++;
    });
  }

  /**
   * Returns a function, which will unsubscribe/detach the event on calling.
   */
  viewingListener(threadId: number) {
    const viewingRef = this._generateRef(`/Thread-${threadId}/viewing user`);
    const threadStore = useThreadStore();

    return onValue(viewingRef, (snapshot) => {
      threadStore.activeThreadUsersMap = snapshot.val();
    });
  }

  commentTypingListener(threadId: string | number) {
    const typingRef = this._generateRef(
      `/ThreadID-${threadId}/commenting user`
    );

    return onValue(typingRef, (snapshot) => {
      const rootStore = useRootStore();
      const { typingUsers } = storeToRefs(rootStore);

      const data: {
        [userId: string]: {
          [key: string]: any;
          email: string;
          firstname: string;
          id: number;
          lastname: string;
          time: number;
        };
      } | null = snapshot.val();

      typingUsers.value = {};

      if (!data) return;

      Object.values(data).forEach((u) => {
        // only push if user has recently typed, allowPush prevents unnecessary hanging event.
        typingUsers.value[u.id] = {
          name: u.firstname + " " + u.lastname,
          typing: "comment",
          lastUpdated: u.time,
        };
      });
    });
  }

  /**
   * Takes an optional callback, that triggers when this listener get triggered.
   */
  replyTypingListener(threadId: string | number, cb?: () => void) {
    const typingRef = this._generateRef(`/ThreadID-${threadId}/replying user`);

    return onValue(typingRef, (snapshot) => {
      const rootStore = useRootStore();
      const { typingUsers } = storeToRefs(rootStore);

      const data: {
        [userId: string]: {
          [key: string]: any;
          email: string;
          firstname: string;
          id: number;
          lastname: string;
          time: number;
        };
      } | null = snapshot.val();

      typingUsers.value = {};

      if (data) {
        Object.values(data).forEach((u) => {
          // only push if user has recently typed, allowPush prevents unnecessary hanging event.
          typingUsers.value[u.id] = {
            name: u.firstname + " " + u.lastname,
            typing: "reply",
            lastUpdated: u.time,
          };
        });
      }

      if (cb) {
        cb();
      }
    });
  }

  /**
   * Takes an optional callback, that triggers when this listener get triggered.
   */
  emailReplyingListener(
    inboxId: string | number,
    threadId: string | number,
    cb?: () => void
  ) {
    const typingRef = this._generateRef(
      `/Mailbox-${inboxId}/Thread-${threadId}/replying user`
    );
    return onValue(typingRef, (snapshot) => {
      const rootStore = useRootStore();
      const { replyingUsers } = storeToRefs(rootStore);
      const data: TeamMate | null = snapshot.val();
      replyingUsers.value = {};
      if (data) {
        Object.values(data).forEach((u) => {
          // only push if user has recently typed, allowPush prevents unnecessary hanging event.
          replyingUsers.value[u.id] = u;
        });
      }
      if (cb) {
        cb();
      }
    });
  }

  /**
   * Setup a listener to tag event, and update the store state.
   *
   * Returns a function, which will unsubscribe/detach the event on calling.
   */
  addNoteListener() {
    const addNotesRef = this._generateRef("/notes");
    const rootStore = useRootStore();
    const threadStore = useThreadStore();
    const { threadsDetailMap } = storeToRefs(threadStore);

    return onValue(addNotesRef, (snapshot) => {
      const userStore = useUserStore();
      const inboxStore = useInboxStore();
      const threadStore = useThreadStore();

      const { isInboxUniversal } = storeToRefs(inboxStore);
      const { user } = storeToRefs(userStore);
      const { threads } = storeToRefs(threadStore);

      const data: {
        [key: string]: unknown;
        threadID: number;
        mailboxID: number;
        noteData: {
          id: number;
          body: string;
          attachments?: ThreadAttachment[];
          time: number;
          sentBy: {
            id: number;
            firstname: string;
            lastname: string;
            email: string;
            avatarTag: string;
            avatarUrl: string;
            name: string;
          };
          snippet: string;
        };
      } = snapshot.val();

      if (!data) return;

      // Pushing the data into threadDetailMap comment data
      const { threadID } = data;
      const params: Record<string, any> = router.currentRoute.value.params;
      const inboxId = params.id as string;

      const { noteData } = data;

      const attachments: ThreadItemMsg["attachments"] =
        noteData.attachments ?? [];
      const foundThread = threads.value.find((t) => t.id === threadID);

      let latestNoteId: number | undefined = 0;

      const threadItems = threadsDetailMap.value[threadID]?.threadDetail.items;

      if (threadItems) {
        for (const item of threadItems) {
          if (item.type === "comment") {
            latestNoteId = item.data.id;
            break;
          }
        }
      }
      if (latestNoteId === undefined) {
        latestNoteId = 0;
      }

      // Only push if the sender is not the one who receives
      if (
        user.value?.id !== noteData.sentBy.id &&
        noteData.id !== latestNoteId
      ) {
        if (foundThread) {
          foundThread.snippet = noteData.snippet;
          foundThread.snippetType = "note";
        }

        threadsDetailMap?.value[threadID]?.threadItems.push({
          attachments,
          content: noteData.body,
          createdAt: dayjs().toISOString(),
          type: "msg",
          deliveryStatus: "delivered",
          id: noteData.id.toString(),
          msgType: "note",
          order: "sent",
          user: {
            id: noteData.sentBy.id.toString(),
            firstName: noteData.sentBy.firstname,
            lastName: noteData.sentBy.lastname,
            name: noteData.sentBy.name,
            avatarTag: noteData.sentBy.avatarTag,
          },
        });
      }

      const currentTime = new Date().getTime();

      if (
        !this._lastExecutedStatsTime ||
        currentTime - this._lastExecutedStatsTime >= 5 * 1000
      ) {
        // execute the function
        // fetch inbox async with stats to update the count
        if (isInboxUniversal.value) {
          inboxStore.fetchInboxes(true);
        } else {
          inboxStore.fetchInboxById(inboxId);
        }
        rootStore.fetchNotification();
        // update the last executed time
        this._lastExecutedStatsTime = currentTime;
      }
    });
  }

  snoozeThreadListener() {
    const rootStore = useRootStore();
    const snoozeThreadRef = this._generateRef("/snooze");

    let i = 0;

    return onValue(snoozeThreadRef, (snapshot) => {
      if (i !== 0) {
        this._handleThreadAction(snapshot);
        rootStore.fetchNotification();
      }
      i++;
    });
  }

  moveToInboxThreadListener() {
    const rootStore = useRootStore();
    const moveToInboxThreadRef = this._generateRef("/moveToInbox");

    let i = 0;

    return onValue(moveToInboxThreadRef, (snapshot) => {
      if (i !== 0) {
        this._handleThreadAction(snapshot);
        rootStore.fetchNotification();
      }
      i++;
    });
  }

  deleteThreadListener() {
    const rootStore = useRootStore();
    const deleteThreadRef = this._generateRef("/delete");

    let i = 0;

    return onValue(deleteThreadRef, (snapshot) => {
      if (i !== 0) {
        this._handleThreadAction(snapshot);
        rootStore.fetchNotification();
      }
      i++;
    });
  }

  newReleaseListener() {
    const rootStore = useRootStore();
    const newReleaseRef = this._generateRef(
      "/version",
      "/HWRelease/hwvuefrontend"
    );
    return onValue(newReleaseRef, (snapshot) => {
      if (snapshot.val() != RELEASE_NUMBER) {
        if (rootStore.newReleaseToastId !== null) {
          toast.dismiss(rootStore.newReleaseToastId);
        }
        rootStore.newReleaseToastId = toast.info(
          {
            component: ReleaseToast,
            props: {},
          },
          {
            position: POSITION.BOTTOM_RIGHT,
            hideProgressBar: false,
            timeout: false,
            closeOnClick: false,
          }
        );
      }
    });
  }

  closeThreadListener() {
    const rootStore = useRootStore();
    const closeThreadRef = this._generateRef("/close");

    let i = 0;

    return onValue(closeThreadRef, (snapshot) => {
      if (i !== 0) {
        this._handleThreadAction(snapshot);
        rootStore.fetchNotification();
      }
      i++;
    });
  }

  deleteMessageListener() {
    const deleteMessageRef = this._generateRef("/deleteMessage");

    return onValue(deleteMessageRef, (snapshot) => {
      const threadStore = useThreadStore();

      const { threadsDetailMap } = storeToRefs(threadStore);

      const data: {
        [key: string]: unknown;
        threadID: number;
        mailboxID: number;
        managerID: number;
        messageID: number;
        inboxType: string;
      } = snapshot.val();

      if (!data) return;

      if (threadsDetailMap.value[data.threadID]) {
        threadsDetailMap.value[data.threadID]?.threadDetail.items.forEach(
          (item, index) => {
            if (item.data.id && item.data.id === data.messageID) {
              threadsDetailMap.value[data.threadID]?.threadDetail.items.splice(
                index,
                1
              );
            }
          }
        );

        threadsDetailMap.value[data.threadID]?.threadItems.forEach(
          (item, index) => {
            if (item.type === "msg" && item.id === String(data.messageID)) {
              threadsDetailMap.value[data.threadID]?.threadItems.splice(
                index,
                1
              );
            }
          }
        );
      }
    });
  }

  deleteNoteListener() {
    const deleteNoteRef = this._generateRef("/deleteNote");

    return onValue(deleteNoteRef, (snapshot) => {
      const threadStore = useThreadStore();

      const { threadsDetailMap } = storeToRefs(threadStore);

      const data: {
        [key: string]: unknown;
        threadID: number;
        mailboxID: number;
        managerID: number;
        commentID: number;
      } = snapshot.val();

      if (!data) return;

      if (threadsDetailMap.value[data.threadID]) {
        threadsDetailMap.value[data.threadID]?.threadDetail.items.forEach(
          (item, index) => {
            if (item.data.id && item.data.id === data.commentID) {
              threadsDetailMap.value[data.threadID]?.threadDetail.items.splice(
                index,
                1
              );
            }
          }
        );

        threadsDetailMap.value[data.threadID]?.threadItems.forEach(
          (item, index) => {
            if (
              item.type === "msg" &&
              item.msgType === "note" &&
              item.id === String(data.commentID)
            ) {
              threadsDetailMap.value[data.threadID]?.threadItems.splice(
                index,
                1
              );
            }
          }
        );
      }
    });
  }

  assignThreadListener() {
    const rootStore = useRootStore();
    const assignThreadRef = this._generateRef("/assign");

    let i = 0;

    return onValue(assignThreadRef, (snapshot) => {
      if (i !== 0) {
        this._handleThreadAction(snapshot);
        rootStore.fetchNotification();
      }
      i++;
    });
  }

  spamThreadListener() {
    const rootStore = useRootStore();
    const closeThreadRef = this._generateRef("/spam");

    let i = 0;

    return onValue(closeThreadRef, (snapshot) => {
      if (i !== 0) {
        this._handleThreadAction(snapshot);
        rootStore.fetchNotification();
      }
      i++;
    });
  }

  /**
   * Returns a function, which will unsubscribe/detach the event on calling.
   */
  addTagListener() {
    const addTagRef = this._generateRef("/addTag");

    let i = 0;

    return onValue(addTagRef, (snapshot) => {
      if (i !== 0) {
        const userStore = useUserStore();
        const threadStore = useThreadStore();

        const { user } = storeToRefs(userStore);
        const { threads, threadsDetailMap } = storeToRefs(threadStore);

        const data: {
          [key: string]: unknown;
          threadID: number[];
          inboxThreadMap: {
            [inboxId: string]: {
              threadIds: number[];
            };
          };
          tags: {
            color: string;
            name: string;
            id: number;
          }[];
          user: {
            [key: string]: unknown;
            id: string;
          };
        } = snapshot.val();

        const { threadID, tags } = data;

        if (user.value?.id.toString() === data.user.id.toString()) return;

        if (threadID && threadID.forEach) {
          threadID.forEach((t) => {
            const threadDetail = threadsDetailMap.value[t]?.threadDetail;
            if (threadDetail) {
              const allTags: ThreadTag[] = threadDetail.tags;
              tags.forEach((tag) => {
                allTags.push(tag);
              });
              threadDetail.tags = allTags;
            }
          });
        }

        const allTags: ThreadTag[] = tags;
        threads.value.forEach((thread) => {
          if (threadID.includes(thread.id)) {
            thread.tags?.push(...allTags);
          }
        });
      }
      i++;
    });
  }

  notificationListener(userId: number) {
    const rootStore = useRootStore();
    const notificationRef = this._generateRef(`/userNotification/${userId}/`);
    let i = 0;

    return onValue(notificationRef, (_) => {
      if (i !== 0) {
        // fetch notifications
        rootStore.fetchNotification();
      }
      i++;
    });
  }

  playNotificationSoundListener(userId: number) {
    const { playSound } = useSound("receive");
    const playNotificationSoundRef = this._generateRef(
      `/playNotificationSound/${userId}`
    );
    let i = 0;

    return onValue(playNotificationSoundRef, (_) => {
      //event settings check is added in backend
      if (i !== 0) {
        playSound();
      }
      i++;
    });
  }

  threadSeenListener() {
    const threadSeenRef = this._generateRef(`/updateInboxSeenLabel`);
    const threadStore = useThreadStore();
    const { threadsDetailMap } = storeToRefs(threadStore);
    let i = 0;

    return onValue(threadSeenRef, (snapshot) => {
      if (i !== 0) {
        const data: {
          unreadCount: string;
          threadID: number;
          [key: string]: any;
        } = snapshot.val();

        // update unreadCount
        const thread = threadsDetailMap.value[data.threadID];
        if (thread) {
          thread.threadDetail.unreadCount = data.unreadCount;

          if (data.unreadCount === "0") {
            thread.threadItems = thread.threadItems.map((i) => ({
              ...i,
              isSeen: true,
            }));
          }
        }
      }
      i++;
    });
  }

  shareDraftListener(inboxId: string, threadId: string) {
    const draftRef = this._generateRef(
      `/Mailbox-${inboxId}/Thread-${threadId}/shareDraft`
    );
    let i = 0;

    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const userId: number | undefined = user.value?.id;

    return onValue(draftRef, (snapshot) => {
      if (i !== 0) {
        const { data } = snapshot.val();
        const { sharedUsers } = JSON.parse(base64Decoder(data));
        if (
          sharedUsers.length === 0 ||
          (sharedUsers.length > 0 && sharedUsers.includes(userId))
        ) {
          this._handleShareDraftChanges(inboxId, threadId);
        }
      }
      i++;
    });
  }

  removeDraftListener(inboxId: string, threadId: string) {
    const draftRef = this._generateRef(
      `/Mailbox-${inboxId}/Thread-${threadId}/removeDraft`
    );

    return onValue(draftRef, (snapshot) => {
      const data: { id: number; at: number } = snapshot.val();

      const emailStore = useEmailStore();
      const { emailDraftsMap } = storeToRefs(emailStore);

      if (emailDraftsMap.value[threadId]) {
        emailDraftsMap.value[threadId] = emailDraftsMap.value[threadId]?.filter(
          (d) => d.draftID !== data?.id
        );
      }
    });
  }
  editDraftListener(
    inboxId: string,
    threadId: string,
    cb?: (data: {
      [draftId: string]: {
        by: number;
        at: number;
      };
    }) => void
  ) {
    const draftRef = this._generateRef(
      `/Mailbox-${inboxId}/Thread-${threadId}/editDraft`
    );

    return onValue(draftRef, (snapshot) => {
      const data: {
        [draftId: string]: {
          by: number;
          at: number;
        };
      } = snapshot.val();

      if (cb) {
        cb(data);
      }
    });
  }
  lockDraftListener(
    inboxId: string,
    threadId: string,
    childType: "added" | "remove",
    cb?: (userId: string) => void
  ) {
    const draftRef = this._generateRef(
      `/Mailbox-${inboxId}/UnivThread-${threadId}/lockDraft`
    );

    if (childType === "added") {
      return onChildAdded(draftRef, (snapshot) => {
        const userId = snapshot.key as string;

        if (cb) {
          cb(userId);
        }
      });
    } else {
      return onChildRemoved(draftRef, (snapshot) => {
        const userId = snapshot.key as string;

        if (cb) {
          cb(userId);
        }
      });
    }
  }
  threadStatusListener(inboxId: string, threadId: string) {
    const statusRef = this._generateRef(
      `/Mailbox-${inboxId}/Thread-${threadId}/message status`
    );

    return onValue(statusRef, (snapshot) => {
      const threadStore = useThreadStore();
      const { threadsDetailMap } = storeToRefs(threadStore);

      const threadData = threadsDetailMap.value[threadId];

      const data: {
        action: string;
        firebaseType: string;
        id: number;
        inboxType: number;
        mailboxID: number;
        managerID: number;
        status: "Sent" | "Delivered" | "Read";
        threadID: number;
        time: number;
      } = snapshot.val();

      const foundThreadItem = threadData?.threadItems.find(
        (i) => i.id === data?.id.toString()
      );

      if (foundThreadItem && foundThreadItem.type === "msg") {
        const statusEnum: {
          [key in typeof data.status]: "sent" | "delivered" | "read";
        } = {
          Sent: "sent",
          Delivered: "delivered",
          Read: "read",
        };
        // Update deliver status
        foundThreadItem.deliveryStatus = statusEnum[data.status];
      }
    });
  }
}

export default SocketListener;
